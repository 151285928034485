<template>
	<div class="stores">
		<van-nav-bar
		class="nav_bar_color"
		title="门店信息设置"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		>
	</van-nav-bar>
		<div class="stores_content">
			<div class="stores_title">
				门店信息
			</div>
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.shortname" placeholder="请输入门店名称" />
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.contact" placeholder="点击输入联系电话" />
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.bio" placeholder="请输入门店口号" />
			<div class="stores_chexbox" @click="isifbio">
				<img v-if="form.if_bio == 0" src="@/assets/drivroute/icon_weixuanz.png" mode="">
				<!-- 选中 -->
				<img v-else src="@/assets/drivroute/icon_xuandui.png" mode="">
				<span>统一使用门店口号代替个人口号</span>
			</div>
			
			<!-- 选择地址 -->
			<div class="stores_map">
				<div @click="handAddress" class="input_list" style="display: flex;justify-content: space-between;">
					<span v-if="form.address" class="van-multi-ellipsis--l2">{{form.address}}</span>
					<span v-if="!form.address" class="van-multi-ellipsis--l2 address">请选择门店地址</span>
					<img src="@/assets/common/icon_yjt.png" mode="">
				</div>
			</div>
			
			<!-- 分割线 -->
			<div class="stores_line">
				
			</div>
			
			<div class="stores_title">
				紧急救援 24小时抢修热线
			</div>
			
			<div class="stores_deputytitle">
				爱车保养 联系人信息
			</div>
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.emergency_contact" placeholder="请输入紧急救援24小时抢修热线" />
			
			<div class="stores_deputytitle">
				爱车保养 联系人信息
			</div>
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.car_care_username" placeholder="请输入姓名" />
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.car_care_position" placeholder="请输入岗位" />
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.car_care_contact" placeholder="请输入爱车保养联系电话" />
			
			<div class="stores_deputytitle">
				一键续保 联系人信息
			</div>
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.renew_username" placeholder="请输入姓名" />
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.renew_position" placeholder="请输入岗位" />
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.renew_contact" placeholder="请输入一键续保联系电话" />
			
			<div class="stores_deputytitle">
				SOS事故理赔 联系人信息
			</div>
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.sos_username" placeholder="请输入姓名" />
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.sos_position" placeholder="请输入岗位" />
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.sos_contact" placeholder="请输入SOS事故理赔联系电话" />
			
			<div class="stores_deputytitle">
				贴心客服 联系人信息
			</div>
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.intimate_username" placeholder="请输入姓名" />
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.intimate_position" placeholder="请输入岗位" />
			<input class="input_list" placeholder-class="placeholderinput" v-model="form.intimate_contact" placeholder="请输入贴心客服联系电话" />
		</div>
		
		<div class="user_footer" style="height: 91px;"  :style="{'top':(docmHeight - 91) + 'px'}">
			<div class="" @click="save">
				保存门店信息
			</div>
			
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form:{},
			};
		},
		computed: {    
			docmHeight() {
				console.log(this.$store.state.height)
				return this.$store.state.height;
			}
		},
		mounted(){
			this.getstoreinfo()
		},
		methods:{
			// 地址选择
			handAddress() {
				const key = 'CYWBZ-R5UKV-LFGPO-UDLQ2-E5DJ7-MHF7J'; //使用在腾讯位置服务申请的key
				const referer = '凯迪销售助手'; //调用插件的app的名称
				const category = '生活服务,娱乐休闲';
				this.$router.replace({path:'/map-selsc'})
				// this.$router.push({
				//   path: `plugin://chooseLocation/index?key=${key}&referer=${referer}&category=${category}`
				// });
				// window.location.href = 'https://apis.map.qq.com/tools/locpicker?search=1&type=0&backurl=http://192.168.1.123/#/stores-information&key=O6PBZ-5Y5KD-Z3T4Z-PMQFK-32OFH-JMB6C&referer=凯迪销售助手'
			},
			// 保存门店数据
			async save(){
				const {data:res} = await this.$http.post('/api/scsj/edit_store_info_1_7_1',this.form)
				if(res.code == 1){
					this.$toast.success('保存成功')
					this.$router.go(-1)
				}else{
					this.$toast.fail(res.msg)
				}
			},
			
			// 获取门店数据
			async getstoreinfo(){
				const {data:res} = await this.$http.post('/api/scsj/get_store_info_1_7_1',{store_id:this.store_id})
				if(res.code == 1){
					this.form = res.data.res
					this.form.if_bio == 0?this.checkbox1=false:this.checkbox1=true
					if(this.$route.query?.loc){
						const loc = JSON.parse(this.$route.query.loc)
						this.form.address = loc.poiaddress
						this.form.lng_lat = loc.latlng.lng + ',' + loc.latlng.lat
					}
				}
			},
			
			// 勾选口号
			isifbio(){
				this.form.if_bio == 1?this.form.if_bio = 0:this.form.if_bio = 1
			}
		}
		
		
	}
</script>

<style lang="scss" scoped>
.stores_content{
	padding: 0 40px 230px ;
	.stores_chexbox{
		margin:40px 0;
		display: flex;
		align-items: center;
		align-items: center;
		img{
			height: 48px;
			width: 48px;
		}
		span{
			margin-left: 20px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #ECECEC;
			line-height: 28px;
		}
	}
	.stores_map{
		div{
			display: flex;
			align-items: center;
			justify-content: space-between;
			span{
				line-height: 30px;
			}
			img{
				width: 48px;
				height: 48px;
			}
		}
	}
}

// 分割线
.stores_line{
	height: 2px;
	width: 100%;
	box-sizing: border-box;
	background: #D8D8D8;
	margin:50px 0;
}

.stores_title{
	text-align: left;
	margin-top: 30px;
	font-size: 36px;
	font-family: HYQiHeiY3-45 !important;
	font-weight: normal;
	color: #ECECEC;
	line-height: 36px;
}

.input_list{
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		background: #222222;
		box-sizing: border-box;
		color: #ffffff;
		border: none;
		line-height: 92px;
		padding: 0 28px;
		width: 670px;
		height: 92px;
		margin-top: 32px;
	}
	
.stores_deputytitle{
	text-align: left;
	margin-top: 50px;
	font-size: 28px;
	font-family: HYQiHeiY3-45 !important;
	font-weight: normal;
	color: #ECECEC;
	line-height: 28px;
}

// 底部按钮
.user_footer{
	z-index: 99;
	position: fixed;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #000000;
	text-align: center;
	div{
		width: 670px;
		height: 92px;
		line-height: 92px;
		background: #FA0037;
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}

.address{
	color: #808080;
	font-family: HYQiHeiY3-55;
}
</style>
